export enum Section {
  DESCRIPTION = "description",
  PROJECT_DETAILS = "project-details",
  VIRTUAL_TOUR = "virtual-tour",
  SITE_PLAN = "site-plan",
  LOCATION_MAP = "location-map",
  GALLERY = "gallery",
  UNIT_MIX = "unit-mix",
  AVAILABLE_UNITS = "available-units",
  FLOOR_PLANS = "floor-plans",
  SALES_TRANSACTION = "sales-transaction",
  SCROLL_TO_ENQUIRY = "scroll-to-enquiry",
  CURATED_PROPERTIES = "curated-properties",
  CONTACT_SALES = "contact-sales",
}
